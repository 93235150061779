import React, { useState } from 'react';
import { Link, withPrefix } from 'gatsby';
import { genericHashLink } from 'react-router-hash-link';
import useWindowScroll from '../hooks/useWindowScroll';
const Header = ({ siteTitle }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    console.log(isScrolled);
    useWindowScroll((x, y) => {
        console.log('scrolling, ');
        if (y > window.innerHeight) {
            console.log('scrolled below 100vh');
            setIsScrolled(true);
        } else {
            console.log('scrolled above 100vh');
            setIsScrolled(false);
        }
    });
    const handleMenuButtonClick = (e) => {
        e.preventDefault();
        setMenuOpen((state) => !state);
    };
    const handleCloseButtonClick = (e) => {
        e.preventDefault();
        setMenuOpen(false);
    };
    const ScrollLink = genericHashLink(Link);
    return (
        <React.Fragment>
            <nav className="nav">
                <Link to="/" className="nav-logo">
                    <img src={withPrefix('images/logo-full.svg')} alt={siteTitle} />
                </Link>
                <Link to="/" className={"nav-logo--small" + ((isScrolled) ? ' _is-visible': '')}>
                    <img src={withPrefix('images/logo-e-square.svg')} alt={siteTitle} />
                </Link>
                <Link to="#main-navigation" className="nav-button nav-trigger" onClick={handleMenuButtonClick}>
                    <span className="type-label">Menu</span>
                    <svg viewBox="0 0 48 48" width="20" height="20">
                        <use xlinkHref="#icon-menu" />
                    </svg>
                </Link>
            </nav>
            <nav id="main-navigation" className={menuOpen ? 'nav-menu _is-open' : 'nav-menu'}>
                <Link to="#close-navigation" className="nav-close nav-trigger" title="Close navigation" onClick={handleCloseButtonClick}>
                    <span className="_is_visually-hidden">Close navigation</span>
                    <svg viewBox="0 0 24 24" width="24" height="24">
                        <use xlinkHref="#icon-cross" />
                    </svg>
                </Link>
                <ul className="nav-links">
                    <li>
                        <Link to="/" className="type-label nav-link" activeClassName="_is-active" title="Home">
                            Home
                        </Link>
                    </li>
                    <li>
                        <ScrollLink
                            to="/#projects"
                            className="type-label nav-link nav-jump"
                            activeClassName="_is-active"
                            title="See example projects"
                        >
                            Projects
                        </ScrollLink>
                    </li>
                    <li>
                        <Link to="/services" className="type-label nav-link" activeClassName="_is-active" title="Services">
                            Services
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact" className="type-label nav-link" activeClassName="_is-active" title="Contact">
                            Contact
                        </Link>
                    </li>
                </ul>
            </nav>
        </React.Fragment>
    );
};
export default Header;